.question-drawer .conditions-container {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.question-drawer .condition-group-container {
	padding: 16px;
	background-color: #f0f0f0;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.question-drawer .condition-group-container .condition-item {
	padding: 16px;
	background-color: #d1cece;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr min-content;
	align-items: flex-start;
	gap: 10px;
}

.operatorSelector {
	display: flex;
	justify-content: center;
	align-items: center;
}

.operatorButton {
	background: none;
	border: none;
	padding: 0;
	margin: 0 5px;
	cursor: pointer;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
}

.operatorButton .active {
	fill: #1890ff;
	color: #1890ff;
}

.operatorButton .inactive {
	fill: gray;
	color: gray;
}

.operatorLabel {
	margin: 0 5px;
	color: #1890ff;
	display: grid;
	font-size: 18px;
	font-weight: bold;
	text-align: center;
}

.conditionButtonContainer {
	padding: 0 10px;
	display: flex;
	justify-content: center;
}

.conditionButton {
	margin: 0 5px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	transform: scale(1.5);
	color: #1890ff;
}

.selector {
	height: fit-content;
	max-width: 100%;
	min-width: 100px;
	text-overflow: ellipsis;
}

.conditionEvaluation {
	display: flex;
	flex-direction: column;
	position: absolute;
	background-color: #f9f9f9;
	border: 1px solid #d3d3d3;
	width: 100px;
	height: fit-content;
	z-index: -10;
	bottom: 100%;
	right: 0;
	margin-bottom: 6px;

}